import React from 'react';
import path from 'path-browserify';
import { ChevronLeft, ChevronRight } from 'react-feather';
import classnames from 'classnames';
import { Link } from 'gatsby';

import { addTrailingSlash } from '../../lib/helpers';

import * as styles from './index.module.scss';

export interface PageContextProps {
  pageContext: {
    humanPageNumber: number;
    limit: number;
    numberOfPages: number;
    skip: number;
    pageNumber: number;
  };
  rootPath: string;
}

/*
Human page number is the current page number in which the user is in and displayed to human. For eg: Fifth page as (5)
paginationNumbers is in the array format, For 5 pages it has [0, 1, 2, 3, 4]
*/
const Pagination = ({ pageContext, rootPath }: PageContextProps) => {
  const { humanPageNumber, numberOfPages } = pageContext;
  const paginationNumbers = [...Array(numberOfPages).keys()];

  const isOverFivePages = numberOfPages > 5;
  const isInMidDisplayRange = (humanPageNumber: number, page: number) => Math.abs(humanPageNumber - page - 1) < 2;

  if (numberOfPages <= 1) return null;

  return (
    <nav className={classnames(styles.customPagination, 'is-flex is-justify-content-center is-align-items-center')}>
      {humanPageNumber > 1 && (
        <Link data-testid="left-nav" className="is-flex" to={addTrailingSlash(path.join(rootPath, `${humanPageNumber < 3 ? '' : humanPageNumber - 1}`))}>
          <ChevronLeft size={42} className={classnames(styles.chevron, 'black-3 mr-5')} />
        </Link>
      )}

      {paginationNumbers.map(
        (page: number): React.ReactNode => {
          if (
            page + 1 === paginationNumbers[paginationNumbers.length - 1] &&
            humanPageNumber > paginationNumbers.length - 3
          ) {
            return (
              <Link
                className={classnames(
                  styles.customPaginationLink,
                  `black-3 ${humanPageNumber === page + 1 ? 'has-text-weight-bold brand-a' : ''}`,
                )}
                key={`pagination-${page + 1}`}
                to={page === 0 ? addTrailingSlash(rootPath) : addTrailingSlash(path.join(rootPath, (page + 1).toString()))}
              >
                {page + 1}
              </Link>
            );
          }

          if (isOverFivePages && humanPageNumber > 3 && page === 1) {
            return (
              <span data-testid="extension" key={`pagination-${page + 1}`} className={classnames(styles.customPaginationLink, 'black-3')}>
                ...
              </span>
            );
          }

          if (isOverFivePages && page === numberOfPages - 2 && humanPageNumber !== page + 1) {
            return (
              <span key={`pagination-${page + 1}`} className={classnames(styles.customPaginationLink, 'black-3')}>
                ...
              </span>
            );
          }
          // shows all the page number if the total page number is less than or equal to 5.
          if (page === 0 || page === numberOfPages - 1 || !isOverFivePages) {
            return (
              <Link
                className={classnames(
                  styles.customPaginationLink,
                  `black-3 ${humanPageNumber === page + 1 ? 'has-text-weight-bold brand-a' : ''}`,
                )}
                key={`pagination-${page + 1}`}
                to={page === 0 ? addTrailingSlash(rootPath) : addTrailingSlash(path.join(rootPath, (page + 1).toString()))}
              >
                {page + 1}
              </Link>
            );
          }
          if (isOverFivePages && isInMidDisplayRange(humanPageNumber, page)) {
            return (
              <Link
                className={classnames(
                  styles.customPaginationLink,
                  `black-3 ${humanPageNumber === page + 1 ? 'has-text-weight-bold brand-a' : ''}`,
                )}
                key={`pagination-${page + 1}`}
                to={page === 0 ? addTrailingSlash(rootPath) : addTrailingSlash(path.join(rootPath, (page + 1).toString()))}
              >
                {page + 1}
              </Link>
            );
          } else return null;
        },
      )}

      {humanPageNumber < numberOfPages && (
        <Link data-testid="right-nav" className="is-flex" to={addTrailingSlash(path.join(rootPath, `${humanPageNumber + 1}`))}>
          <ChevronRight size={42} className={classnames(styles.chevron, 'black-3')} />
        </Link>
      )}
    </nav>
  );
};

export default Pagination;
